import React from 'react';
import PropTypes from 'prop-types';

const PricingPlan = ({ featured, plan, onClick }) => (
    <div className={`pricing__item ${featured ? 'pricing__item--active': ''} `} data-sal="fade" data-sal-easing="ease-in-cubic" data-sal-duration="400">
        <div className="pricing__item-content">
            <h3 className="pricing__item-title">{ plan.title }</h3>
            <p className="pricing__item-price"><span>{ plan.currency }</span><span>{ plan.price }</span>/{ plan.perItem }</p>
            {
                ( plan.planFeatures && plan.planFeatures.length > 0 ) &&
                <ul className="pricing__item-features">
                    {
                        plan.planFeatures.map(feature => (
                            <span className="flex flex-row items-center">
                                <i className="item__icon_small material-icons">{ feature.icon }</i>
                                <li key={ feature.id }>{ feature.title }</li>
                            </span>
                        ))
                    }
                </ul>
            }
        </div>
        { 
            plan.buttonText && 
            <a onClick={ onClick } className={`btn ${!featured ? 'btn--primary': ' bg-pink'}`}>{ plan.buttonText }</a> 
        }
        {
            plan.description?.childMarkdownRemark && 
            <div className="pricing__item-description" dangerouslySetInnerHTML={{ __html: plan.description.childMarkdownRemark.html }} />
        }
    </div>
);

PricingPlan.propTypes = {
    plan: PropTypes.object.isRequired
};


export default PricingPlan;