import React, { useState, useEffect, useRef } from 'react';

import axios from "axios";
import debounce from "lodash.debounce";

const apiUrl = process.env.API_URL;

const LocationSuggest = ( props ) => {

    const [selectedLocation, setSelectedLocation] = useState("");
    const [suggestedLocations, setSuggestedLocations] = useState({
        locations: [],
    });

    const getLocationSuggestions = async searchString => {
        return axios(`${apiUrl}/location-search?search=${searchString}`)
          .then(response => {
            return response.data.map(place => {
              return {
                label: place.description,
                place_id: place.place_id,
              };
            });
          })
          .catch(err => {
            console.error(err);
            return err;
          });
      };
    
      const debouncedSave = useRef(
        debounce(fieldValue => {
          getLocationSuggestions(fieldValue)
            .then(locations => {
              setSuggestedLocations({ locations: locations });
            })
            .catch(err => {
              console.error(err);
            });
        }, 500)
      );
    
    const handleLocationInputChange = fieldValue => {
    
        if (fieldValue && fieldValue.length > 0) {
          debouncedSave.current(fieldValue);
        } else {
          setSuggestedLocations({ locations: [] });
        }
    };

    const onChange = e => {
        e.preventDefault();
        handleLocationInputChange(e.currentTarget.value)
        setSelectedLocation(e.currentTarget.value)
    }

    const handleLocationSelect = location => {
        setSelectedLocation(location.label);
        props.onLocationSelect(location)
        setSuggestedLocations({ locations: [] });

        typeof window !== "undefined" && window.gtag('event', 'search', {
            search_term: location.label
        });
    };

    return (
        <span className={`input-row relative ${props.wrapperClass}`}>
            <input 
                type="text"
                className={`${props.inputClass}`}
                placeholder={(props.placeholder || "Where do you want to search?")}
                value={selectedLocation} 
                onChange={onChange}
            />
            {
                suggestedLocations.locations.length > 0 && (
                    <ul className="dropdown absolute top-full z-10 bg-white py-2 shadow-md rounded-b-lg">
                        { suggestedLocations.locations.map(location => {
                            return (
                                <li
                                    className={"py-2 px-4 cursor-pointer hover:bg-gray"}
                                    onClick={() => handleLocationSelect(location)}
                                >
                                    {location.label}
                                </li>
                                );
                            })}
                    </ul>
                )     
            }
            {
                props.children
            }
        </span>
    )
}

export default LocationSuggest;